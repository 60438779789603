<template>
  <b-card>
    <b-modal
      id="coa-modal"
      title="Add Chart of Account"
      ok-title="Save"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="saveCoa"
      @ok.prevent
      @cancel="resetForm"
      @close="resetForm"
    >
      <div>
        <!-- Account Type -->
        <b-row>
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="accountTypeFilterState"
              invalid-feedback="Please Choose an Account Type"
            >
              <label>Account Type</label>
              <v-select
                v-model="accountTypeFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="accountTypeOptions"
                :reduce="val => val.id"
                :state="accountTypeFilterState"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Main Head -->
        <b-row v-if="accountTypeFilter">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="mainHeadFilterState"
              invalid-feedback="Main Head is Required"
            >
              <label>Main Head</label>
              <v-select
                v-model="mainHeadFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="mainHeadOptions"
                :disabled="!accountTypeFilter"
                :reduce="val => val.id"
                :state="mainHeadFilterState"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Sub Head -->
        <b-row v-if="mainHeadFilter">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="subHeadFilterState"
              invalid-feedback="Sub Head is Required OR press the plus icon to add New Sub Head Name"
            >
              <label>Sub Head</label>
              <v-select
                v-model="subHeadFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subHeadOptions"
                label="gl_name"
                :disabled="!mainHeadFilter"
                :reduce="val => val.id"
                :state="subHeadFilterState"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="mainHeadFilter && !addSubHeadField && !subHeadFilter"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              icon="PlusCircleIcon"
              size="22"
              variant="outline-primary"
              @click="addNewCoa('subHead')"
            />
          </b-col>
          <b-col
            v-if="mainHeadFilter && addSubHeadField && selectedModal"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              icon="MinusCircleIcon"
              size="22"
              variant="outline-primary"
              @click="glNameFilter = null; addSubHeadField= null"
            />
          </b-col>
          <b-col
            v-if="addSubHeadField"
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="New Sub Head Name"
              label-for="gl_name"
              :state="glNameFilterState"
              invalid-feedback="New Sub Head Name is Required"
            >
              <b-form-input
                v-model="glNameFilter"
                :state="glNameFilterState"
                placeholder="Please enter new sub head name"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            v-if="mainHeadFilter && addSubHeadField"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              icon="ArrowRightIcon"
              size="22"
              variant="outline-primary"
              @click="saveCoa"
            />
          </b-col> -->
        </b-row>

        <!-- Child Head -->
        <b-row v-if="subHeadFilter">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="childHeadFilterState"
              invalid-feedback="Child Head is Required OR press the plus icon to add New Sub Head Name"
            >
              <label>Child Head</label>
              <v-select
                v-model="childHeadFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="childHeadOptions"
                :disabled="!subHeadFilter"
                :reduce="val => val.id"
                :state="childHeadFilterState"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="subHeadFilter && !addChildHeadField && !childHeadFilter"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              icon="PlusCircleIcon"
              size="22"
              variant="outline-primary"
              @click="addNewCoa('childHead')"
            />
          </b-col>
          <b-col
            v-if="subHeadFilter && addChildHeadField && selectedModal"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              icon="MinusCircleIcon"
              size="22"
              variant="outline-primary"
              @click="glNameFilter = null; addChildHeadField= null"
            />
          </b-col>
          <b-col
            v-if="addChildHeadField"
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="glNameFilterState"
              invalid-feedback="New Sub Head Name is Required"
              label="New Child Head Name"
            >
              <b-form-input
                v-model="glNameFilter"
                :state="glNameFilterState"
                placeholder="Please enter new child head name"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            v-if="subHeadFilter && addChildHeadField"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              icon="ArrowRightIcon"
              size="22"
              variant="outline-primary"
              @click="saveCoa"
            />
          </b-col> -->
        </b-row>

        <!-- Sub Child Head -->
        <b-row v-if="childHeadFilter">
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="subChildHeadFilterState"
              invalid-feedback="Sub Child Head is Required OR press the plus icon to add New Sub Head Name"
            >
              <label>Sub Child Head</label>
              <v-select
                v-model="subChildHeadFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                :options="subChildHeadOptions"
                :disabled="!childHeadFilter"
                :reduce="val => val.id"
                :state="subChildHeadFilterState"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="childHeadFilter && !addSubChildHeadField"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              v-b-modal.coa-create-modal
              icon="PlusCircleIcon"
              size="22"
              variant="outline-primary"
              @click="addNewCoa('subChildHead')"
            />
          </b-col>
          <b-col
            v-if="childHeadFilter && addSubChildHeadField && selectedModal"
            cols="1"
            md="1"
            align-self="center"
          >
            <feather-icon
              icon="MinusCircleIcon"
              size="22"
              variant="outline-primary"
              @click="glNameFilter = null; addSubChildHeadField= null"
            />
          </b-col>
          <b-col
            v-if="addSubChildHeadField"
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :state="glNameFilterState"
              invalid-feedback="New Sub Head Name is Required"
              label="New Sub Child Head Name"
            >
              <b-form-input
                v-model="glNameFilter"
                :state="glNameFilterState"
                placeholder="Please enter new sub child head name"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Chart of Account
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          v-b-modal.coa-modal
          variant="outline-primary"
        >
          Add Chart Of Account
        </b-button>
      </b-form-group>
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
        searchFn: globalGridSearch
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteCoa(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          <!-- <span v-if="props.formattedRow[props.column.field].gl_code">
            {{ props.formattedRow[props.column.field].gl_name + ('-' + props.formattedRow[props.column.field].gl_code) }}
          </span> -->
          <!-- {{ props.column }} -->
          <!-- {{ props.row.account_type.gl_code }} -->
          {{ props.formattedRow[props.column.field].gl_name }}
          <!-- <span v-if="props.column.field == 'sub_child_head' && props.row.sub_child_head.gl_code">
            {{ props.formattedRow[props.column.sub_child_head].gl_code }}
          </span> -->
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="coa-create-modal"
      title="Update Chart of Account"
      ok-title="Update"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="updateCoa"
    >
      <b-form>
        <b-form-group
          label="Account Type"
          label-for="account-type-filter"
        >
          <v-select
            v-model="accountTypeFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="accountTypeOptions"
            :reduce="val => val.id"
            disabled
            @input="(val) => $emit('update:mainHeadFilter', val)"
          />
        </b-form-group>
        <b-form-group
          label="Main Head"
          label-for="main-head-filter"
        >
          <v-select
            v-model="mainHeadFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="mainHeadOptions"
            :reduce="val => val.id"
            disabled
            @input="(val) => $emit('update:mainHeadFilter', val)"
          />
        </b-form-group>
        <b-form-group
          v-if="subHeadFilter"
          label="Sub Head"
          label-for="sub-head-filter"
        >
          <v-select
            v-model="subHeadFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="subHeadOptions"
            :reduce="val => val.id"
            disabled
            @input="(val) => $emit('update:subHeadFilter', val)"
          />
        </b-form-group>
        <b-form-group
          v-if="childHeadFilter"
          label="Child Head"
          label-for="child-head-filter"
        >
          <v-select
            v-model="childHeadFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="childHeadOptions"
            :reduce="val => val.id"
            disabled
            @input="(val) => $emit('update:childHeadFilter', val)"
          />
        </b-form-group>
        <b-form-group
          v-if="subChildHeadFilter"
          label="Sub Child Head"
          label-for="sub-child-head-filter"
        >
          <v-select
            v-model="subChildHeadFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            :options="subChildHeadOptions"
            :reduce="val => val.id"
            disabled
            @input="(val) => $emit('update:subChildHeadFilter', val)"
          />
        </b-form-group>
        <b-form-group
          label="GL Name"
          label-for="gl_name"
        >
          <b-form-input
            id="gl_name"
            v-model="glNameFilter"
            placeholder="GL Name"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Audit Activity Modal -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard, BPagination, BForm, BFormGroup, BFormInput, BFormSelect, BRow, BCol, VBModal, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'
import ChartOfAccountsService from '@/@service/api/accounting/ChartOfAccounts.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    vSelect,
    BButton,
    RefreshCwIcon,
    ActivityModal,
    BLink,

  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      selectedModal: null,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Account Type',
          field: 'account_type',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Account Type',
            filterFn: this.accountTypeFilterFn,
            filterDropdownItems: [
              { text: 'Asset', value: '1' },
              { text: 'Liabilities & Equity', value: '2' },
              { text: 'Income', value: '3' },
              { text: 'Expense', value: '4' },
            ],
          },
          sortable: false,
        },
        {
          label: 'Main Head',
          field: 'main_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Main Head',
          },
          sortable: false,
        },
        {
          label: 'Sub Head',
          field: 'sub_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Sub Head',
          },
          sortable: false,
        },
        {
          label: 'Child Head',
          field: 'child_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Child Head',
          },
          sortable: false,
        },
        {
          label: 'Sub Child Head',
          field: 'sub_child_head',
          filterOptions: {
            enabled: true,
            filterFn: this.columnFilterFn,
            placeholder: 'Search Sub Child Head',
          },
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      accountTypeFilter: null,
      mainHeadFilter: null,
      subHeadFilter: null,
      subChildHeadValue: '',
      childHeadValue: '',
      childHeadFilter: null,
      subChildHeadFilter: null,
      glNameFilter: null,
      addSubHeadField: false,
      addChildHeadField: false,
      addSubChildHeadField: false,
      coaEditData: null,
      accountTypeOptions: [
        { gl_name: 'Asset', id: '1' },
        { gl_name: 'Liabilities & Equity', id: '2' },
        { gl_name: 'Income', id: '3' },
        { gl_name: 'Expense', id: '4' },
      ],
      mainHeadOptions: [],
      subHeadOptions: [],
      childHeadOptions: [],
      subChildHeadOptions: [],

      accountTypeFilterState: null,
      mainHeadFilterState: null,
      subHeadFilterState: null,
      childHeadFilterState: null,
      subChildHeadFilterState: null,
      glNameFilterState: null,

      // Activity Log Data
      tag: 'chart-of-account',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    accountTypeFilter(newVal) {
      if (newVal == null) {
        this.mainHeadFilter = null
        this.subHeadFilter = null
        this.childHeadFilter = null
        this.subChildHeadFilter = null
        this.glNameFilter = null
      } else if (typeof newVal === 'object') {
        this.getMainCoa(newVal.id)
      } else {
        this.getMainCoa(newVal)
      }
    },
    mainHeadFilter(newVal) {
      if (newVal == null) {
        this.subHeadFilter = null
        this.childHeadFilter = null
        this.subChildHeadFilter = null
        this.glNameFilter = null
      } else if (typeof newVal === 'object') {
        this.getChildCoa(newVal.id, 'subHead')
      } else {
        this.getChildCoa(newVal, 'subHead')
      }
    },
    subHeadFilter(newVal) {
      if (newVal == null) {
        this.childHeadFilter = null
        this.subChildHeadFilter = null
        this.glNameFilter = null
      } else if (typeof newVal === 'object') {
        this.addSubHeadField = false
        this.getChildCoa(newVal.id, 'childHead')
      } else {
        this.addSubHeadField = false
        this.getChildCoa(newVal, 'childHead')
      }
    },
    childHeadFilter(newVal) {
      if (newVal == null) {
        this.subChildHeadFilter = null
        this.glNameFilter = null
      } else if (typeof newVal === 'object') {
        this.addChildHeadField = false
        this.getChildCoa(newVal.id, 'subChildHead')
      } else {
        this.addChildHeadField = false
        this.getChildCoa(newVal, 'subChildHead')
      }
    },
    subChildHeadFilter(newVal) {
      if (newVal == null) {
        this.glNameFilter = null
      } else {
        this.addSubChildHeadField = false
      }
    },
  },
  created() {
    this.getCoaGridData()
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      this.selectedModal = null
      this.glNameFilter = null
    })

    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (bvEvent.type === 'show' && modalId === 'coa-modal') this.resetForm()
    })
  },
  methods: {
    getCoaGridData() {
      ChartOfAccountsService.getAll()
        .then(res => {
          this.rows = res.data.data
        })
    },
    refreshGrid() {
      this.getCoaGridData()
    },

    onRowClick() {
    },
    addNewCoa(target) {
      this.selectedModal = target
      if (target === 'subHead') {
        this.addSubHeadField = true
      } else if (target === 'childHead') {
        this.addChildHeadField = true
      } else if (target === 'subChildHead') {
        this.addSubChildHeadField = true
      }
    },
    formValidate() {
      this.accountTypeFilterState = null
      this.mainHeadFilterState = null
      this.subHeadFilterState = null
      this.childHeadFilterState = null
      this.subChildHeadFilterState = null
      this.glNameFilterState = null

      if (this.accountTypeFilter) {
        this.accountTypeFilterState = null

        if (this.mainHeadFilter) {
          this.mainHeadFilterState = null

          if (this.subHeadFilter) {
            console.log('sub Head')
            console.log(this.subHeadFilter)
            this.subHeadFilterState = null
            this.addSubHeadField = false

            if (this.childHeadFilter) {
              this.childHeadFilterState = null
              this.addChildHeadField = false

              if (this.subChildHeadFilter) {
                this.subChildHeadFilterState = null
                if (this.subChildHeadFilter) {
                  if (this.addSubChildHeadField) {
                    this.subChildHeadFilterState = null
                    if (this.glNameFilter === null || this.glNameFilter === '') {
                      this.glNameFilterState = false
                    }
                  } else {
                    FemsToastrService.error('Please press plus icon to provide New Sub Head Name')

                    return false
                  }
                }
                // inside sub child head
              } else if (this.subChildHeadFilter === null || this.subChildHeadFilter === '') {
                if (this.addSubChildHeadField) {
                  this.subChildHeadFilterState = null
                  if (this.glNameFilter === null || this.glNameFilter === '') {
                    this.glNameFilterState = false
                  }
                } else {
                  this.subChildHeadFilterState = false
                }
              }
            } else if (this.childHeadFilter === null || this.childHeadFilter === '') {
              if (this.addChildHeadField) {
                this.childHeadFilterState = null
                if (this.glNameFilter === null || this.glNameFilter === '') {
                  this.glNameFilterState = false
                }
              } else {
                this.childHeadFilterState = false
              }
            }
          } else if (this.subHeadFilter === null || this.subHeadFilter === '') {
            if (this.addSubHeadField) {
              this.subHeadFilterState = null
              if (this.glNameFilter === null || this.glNameFilter === '') {
                this.glNameFilterState = false
              }
            } else {
              this.subHeadFilterState = false
            }
          }
        } else if (this.mainHeadFilter === null || this.mainHeadFilter === '') {
          this.mainHeadFilterState = false
        }
      } else if (this.accountTypeFilter === null || this.accountTypeFilter === '') {
        this.accountTypeFilterState = false
      }

      if (this.accountTypeFilterState !== false && this.mainHeadFilterState !== false
        && this.subHeadFilterState !== false && this.childHeadFilterState !== false
        && this.subChildHeadFilterState !== false && this.glNameFilterState !== false) {
        return true
      }
      return false
    },
    saveCoa() {
      if (!this.formValidate()) {
        return
      }
      const params = {
        gl_name: this.glNameFilter,
      }

      if (this.selectedModal === 'subHead') {
        params.parent_id = this.mainHeadFilter
        this.addSubHeadField = false
      }
      if (this.selectedModal === 'childHead') {
        params.parent_id = this.subHeadFilter
        this.addChildHeadField = false
      }
      if (this.selectedModal === 'subChildHead') {
        params.parent_id = this.childHeadFilter
        this.addSubChildHeadField = false
      }

      ChartOfAccountsService.saveCoa(params).then(res => {
        if (res.data.status) {
          const responseData = res.data.data
          this.glNameFilter = null
          if (this.selectedModal === 'subHead') {
            this.subHeadFilter = responseData.id
          }
          if (responseData.layer === 3) {
            this.getChildCoa(responseData.parent_id, 'subHead', responseData.id)
            this.subHeadFilter = responseData.id
          }
          if (responseData.layer === 4) {
            this.getChildCoa(responseData.parent_id, 'childHead', responseData.id)
            this.childHeadFilter = responseData.id
          }
          if (responseData.layer === 5) {
            this.getChildCoa(responseData.parent_id, 'subChildHead', responseData.id)
            this.subChildHeadFilter = responseData.id
          }
          this.getCoaGridData()

          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    updateCoa() {
      const params = {
        gl_name: this.glNameFilter,
        id: this.subHeadFilter.id,
      }
      if (this.childHeadFilter) {
        params.id = this.childHeadFilter.id
      }
      if (this.subChildHeadFilter) {
        params.id = this.subChildHeadFilter.id
      }
      ChartOfAccountsService.updateCoa(params.id, params).then(res => {
        if (res.data.status) {
          this.glNameFilter = null
          this.selectedModal = null
          this.getCoaGridData()
          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      }).catch(err => {
        FemsToastrService.error(err.message)
      })
    },
    accountTypeFilterFn(data, filterString) {
      return data.id === Number(filterString)
    },
    columnFilterFn(data, filterString) {
      return (data && data.gl_name
        && data.gl_name.toLowerCase().includes(filterString.toLowerCase()))
    },
    getMainCoa(parentId) {
      this.$http.get(`${window.apiUrl}accounting/main-coa/${parentId}`)
        .then(res => {
          // this.mainHeadFilter = null
          this.mainHeadOptions = res.data.data
        })
    },
    getChildCoa(parentId, target = null, id = null) {
      this.$http.get(`${window.apiUrl}accounting/child-coa/${parentId}`)
        .then(res => {
          if (target === 'subHead') {
            this.addSubHeadField = false
            this.addChildHeadField = false
            this.addSubChildHeadField = false
            this.subHeadOptions = res.data.data
          } else if (target === 'childHead') {
            this.addChildHeadField = false
            this.addSubChildHeadField = false
            this.childHeadOptions = res.data.data
            if (id) this.childHeadFilter = id
          } else if (target === 'subChildHead') {
            this.addSubChildHeadField = false
            this.subChildHeadOptions = res.data.data
          }
        })
    },

    globalGridSearch(row, col, cellValue, searchTerm) {
      return cellValue && cellValue.gl_name
        && cellValue.gl_name.toLowerCase().includes(searchTerm.toLowerCase())
    },

    editRow(rowData) {
      this.resetForm()
      this.coaEditData = rowData
      if (rowData.account_type) {
        this.accountTypeFilter = rowData.account_type
      }

      if (rowData.main_head) {
        this.mainHeadFilter = rowData.main_head
      }
      if (rowData.sub_head) {
        this.subHeadFilter = rowData.sub_head
        this.glNameFilter = rowData.sub_head.gl_name
      }
      if (rowData.child_head) {
        this.childHeadFilter = rowData.child_head
        this.glNameFilter = rowData.child_head.gl_name
      }

      if (rowData.sub_child_head) {
        this.subChildHeadFilter = rowData.sub_child_head
        this.glNameFilter = rowData.sub_child_head.gl_name
      }
      this.$bvModal.show('coa-create-modal')
    },
    deleteCoa(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'danger',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let coaId = rowData.sub_head.id

          if (rowData.sub_child_head) {
            coaId = rowData.sub_child_head.id
          } else if (rowData.child_head) {
            coaId = rowData.child_head.id
          }

          ChartOfAccountsService.deleteCoa(coaId).then(res => {
            if (res.status && res.data.status) {
              this.getCoaGridData()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              FemsToastrService.error(res.data.message)
            }
          })
            .catch(() => {
              FemsToastrService.error('Something went wrong! Please contact your system admin!')
            })
        }
      })
    },
    resetForm() {
      this.accountTypeFilter = null
      this.mainHeadFilter = null
      this.subHeadFilter = null
      this.childHeadFilter = null
      this.childHeadValue = ''
      this.subChildHeadFilter = null
      this.glNameFilter = null

      this.accountTypeFilterState = null
      this.mainHeadFilterState = null
      this.subHeadFilterState = null
      this.childHeadFilterState = null
      this.subChildHeadFilterState = null
      this.glNameFilterState = null

      this.addSubHeadField = false
      this.addChildHeadField = false
      this.addSubChildHeadField = false
    },

    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
